import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import Values from "../components/values/Values_5"
import BlockPromise from "../components/block/BlockThree"


function Reviews() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <BaseLayout PageName="The Pizano Promise">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="The Pizano Promise"
                    Subheader="The Pizano Promise"
                    bgimg={`${rpdata?.gallery?.[0]}`}
                />
            </div>
            <div className="w-full mx-auto py-[100px]">
                <h1 className="text-center pb-10">{rpdata?.dbSlogan?.[5]?.slogan}</h1>
                <BlockPromise
                    title={rpdata?.dbSlogan?.[2]?.slogan}
                    text={rpdata?.dbAbout?.[2]?.text}
                    image={rpdata?.gallery?.[9]}
                />
                <div className="w-full h-auto flex justify-center items-center py-16">

                    <div className="md:w-full w-full h-auto">
                        <Values
                            image1={rpdata?.gallery?.[16]}
                            image3={rpdata?.gallery?.[18]}
                            image2={rpdata?.gallery?.[10]}
                        />
                    </div>
                </div>
            </div>
        </BaseLayout>
    );
}

export default Reviews;
